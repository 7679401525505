@import 'styles/index.less';

.x-user {
    margin-top: 30px;
    padding: @std-padding;
    // padding: @std-padding;
    // width: 100%;
    // margin-top: 30px;
}

// .x-user.x-user-xs .x-user-content {
//     margin: 0 auto;
//     width: 100%;
// }

// .x-user.x-user-sm {
//     margin-top: 30px;
// }

// .x-user.x-user-lg {
//     margin-top: 30px;
// }

// .x-user.x-user-lg .x-user-content {
//     margin: 0;
// }

// .x-user-image {
//     display: block;
//     width: 100%;
// }

.x-user-content {
    display: block;
    max-width: @std-form-width;
    margin: 0 auto;
}

@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;