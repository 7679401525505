@import 'styles/index.less';

.x-datetimeselector {
    margin-top: 5px;
    width: 100%;
}

.x-datetimeselector-radio {
    width: 100%;
}

.x-datetimeselector-radio-group {
    width: 100%;
}

.x-datetimeselector-radio > span:nth-child(2) {
    width: 100%;
    margin-right: -@std-spacing;
}

.x-datetimeselector-picker {
    width: 100%;
}

.x-datetimeselector-hide {
    display: none;
}

.x-datetimeselector-absolute {
    //margin-right: -@std-spacing !important;
}

.x-datetimeselector-unit {
    width: 100px;
}

@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;