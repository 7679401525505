@import 'styles/index.less';

.x-polareditor-add-dataset {
    text-align: center;
}

.x-polareditor-add-dataset::after {
    clear: both;
}

.x-polareditor-dataset-delete {
    text-align: right;
    margin-right: 3px;
}

.x-polareditor-dataset-list {
    width: 100%;
    min-height: 144px;
    max-height: 144px;
    overflow-y: auto;
    border: 1px solid @border-color;
    border-radius: 4px;
}

.x-polareditor-dataset-item {
    padding: @std-padding !important;
}

.x-polareditor-dataset-name {
    width: 100%;
}

.x-polareditor-dataset-action {
    align-self: center;
    margin-left: 5px;
}

.x-polareditor-checkbox {
    margin-bottom: @lg-padding;
}

.x-polareditor-checkbox label {
    margin-left: 0px !important;
}
@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;