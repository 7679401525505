@import 'styles/index.less';

.x-loadspinner {
    height: 100%;
    width: 100%;
}

.x-loadspinner-fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.65);
    overflow: hidden;
}

.x-loadspinner-left {
    display: flex;
    justify-content: flex-start;
    gap: @std-spacing;
}

.x-loadspinner-top {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    flex-direction: column;
    align-items: center;
}

.x-loadspinner-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.x-loadspinner-message-load {
    margin-left: @std-spacing;
}

.x-loadspinner-failed {
    text-align: center;
}

.x-loadspinner-message-fail {
    margin-bottom: @std-spacing;
    font-size: 1.0em;
}

@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;