@import 'styles/index.less';

.x-widgetviewer {
    width: inherit;
    //white-space: nowrap;
}

.x-widgetviewer-skeleton {
    padding: @std-padding;
}

.x-widgetviewer-card {
    // box-shadow: @shadow;
    border-radius: @std-border-radius;
    width: 100%;
}

.x-widgetviewer-card .ant-card-head {
    color: @gray-8 !important;
    background-color: @blue-1 !important;
}

.x-widgetviewer-card.ant-card-bordered {
    border: 1px solid @gray-6;
}

.x-widgetviewer-card.ant-card-small > .ant-card-body {
    padding: @std-padding !important;
}

// Styles to be used in concrete widget viewers.

.x-widgetviewer-text {
    font-size: 1.2em;
    color: @gray-8;
}

.x-widgetviewer-label {
    font-size: 1.2em;
    font-weight: @semi-bold-font-weight;
    color: @gray-8;
}

.x-widgetviewer-description {
    font-size: 1.1em;
    color: @gray-7;
}

.x-widgetviewer-size-xxs {
    font-size: @xxs-text-font-size !important;
}

.x-widgetviewer-size-xs {
    font-size: @xs-text-font-size !important;
}

.x-widgetviewer-size-sm {
    font-size: @sm-text-font-size !important;
}

.x-widgetviewer-size-med {
    font-size: @med-text-font-size !important;
}

.x-widgetviewer-size-lg {
    font-size: @lg-text-font-size !important;
}

.x-widgetviewer-size-xl {
    font-size: @xl-text-font-size !important;
}

.x-widgetviewer-size-xxl {
    font-size: @xxl-text-font-size !important;
}

.x-widgetviewer-color-black {
    color: @gray-13 !important;
}

.x-widgetviewer-color-white {
    color: @gray-1 !important;
}

.x-widgetviewer-color-lightgray {
    color: @gray-7 !important;
}

.x-widgetviewer-color-gray {
    color: @gray-8 !important;
}

.x-widgetviewer-color-darkgray {
    color: @gray-9 !important;
}

.x-widgetviewer-color-blue {
    color: @blue-6 !important;
}

.x-widgetviewer-color-red {
    color: @red-6 !important;
}

.x-widgetviewer-color-green {
    color: @green-6 !important;
}

.x-widgetviewer-color-purple {
    color: @purple-6 !important;
}

.x-widgetviewer-color-pink {
    color: @pink-6 !important;
}

.x-widgetviewer-color-orange {
    color: @orange-6 !important;
}

.x-widgetviewer-color-yellow {
    color: @yellow-6 !important;
}

.x-widgetviewer-weight-thin {
    font-weight: @extra-light-font-weight !important;
}

.x-widgetviewer-weight-normal {
    font-weight: @regular-font-weight !important;
}

.x-widgetviewer-weight-bold {
    font-weight: @semi-bold-font-weight !important;
}

.x-widgetviewer-justification-left {
    justify-content: flex-start !important;
}

.x-widgetviewer-justification-center {
    justify-content: center !important;
}

.x-widgetviewer-justification-right {
    justify-content: flex-end !important;
}

@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;