@import 'styles/index.less';

.x-loadskeleton {
    height: 100%;
    width: 100%;
}

.x-loadskeleton-item {
    display: flex;
    width: 100%;
}

.x-loadskeleton-skeleton {
    padding: @std-padding 0;
}

.x-loadskeleton-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.x-loadskeleton-loading {
    display: flex !important;
}

.x-loadskeleton-failed {
    margin: 50px 0;
}

.x-loadskeleton-loading .x-loadskeleton-message {
    margin-left: 10px;
}

.x-loadskeleton-failed {
    text-align: center;
}

.x-loadskeleton-failed .x-loadskeleton-message {
    margin-bottom: 10px;
    font-size: 1.0em;
}

.x-loadskeleton-row {
    display: flex;
    gap: @std-spacing;
}

.x-loadskeleton-checkbox {
    width: 32px !important;
}

div.x-loadskeleton-tiny {
    // width: 100px !important;
    // max-width: 100%;
    width: 15% !important;
}

div.x-loadskeleton-short {
    // width: 150px !important;
    // max-width: 100%;
    width: 25% !important;
}

div.x-loadskeleton-medium {
    // width: 300px !important;
    // max-width: 100%;
    width: 50% !important;
}

div.x-loadskeleton-long {
    // width: 450px !important;
    // max-width: 100%;
    width: 75% !important;
}

div.x-loadskeleton-fill {
    width: 100% !important;
}
@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;