@import 'styles/index.less';

.x-appletviewer {
    width: 100%;
    
}
.x-appletviewer-card {
    //width: 100%;
    //box-shadow: @shadow;
    //border: 1px solid @border-color !important;
    //background-color: @light-background-color !important;
}

.x-appletviewer-panel {
    width: 100%;
}

.x-appletviewer .ant-card-head {
    color: @gray-8;
    font-weight: @semi-bold-font-weight;
    font-size: @h8-font-size !important;
}

.x-appletviewer-config {
    margin-bottom: @std-padding;
    color: @gray-8;
    font-weight: @semi-bold-font-weight;
}

@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;