@import 'styles/index.less';

.x-errorcell {
    position: relative;
    width: 100%;
    text-align: center;
}

.x-errorcell input {
    border: 0px;
    caret-color: transparent;
}

.x-errorcell-mark {
    position: absolute;
    transform: rotate(45deg);
    top: -1px;
    right: -17px;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid @cell-error-color;
    cursor: pointer;
}

.x-errorcell-header {
    color: @error-color;
    font-weight: @bold-font-weight;
}

.x-errorcell-message {
    color: @text-color !important;
    padding: 2px !important;
}

@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;