@import 'styles/index.less';

.x-infopanel {
    font-family: @std-font-family;
}

 .x-infopanel-shadow {
     box-shadow: @shadow;
 }

.x-infopanel-header {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 25px;
}

.x-infopanel-body {
    font-size: @xl-text-font-size;
    display: flex;
    text-align: center;
    padding: 40px 25px 25px 25px;
}

.x-infopanel-title {
    font-weight: bold;
    font-size: @h5-font-size;
}

.x-infopanel-subtitle {
    font-size: @lg-text-font-size;
}

.x-infopanel-triangle {
    position: absolute;
    bottom: -25px;
    width: 0;
    height: 0;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;
    border-top: 30px solid;
}

@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;