@import 'styles/index.less';

.x-register {
    // max-width: @std-form-width;
    // margin: 0 auto;
}

.x-register-alert {
    font-size: @lg-text-font-size;
    color: @alt-text-color;
}

.x-register-back {
    font-size: 18px;
}

.x-register-submit {
    width: 100%;
    margin: 10px 0;
}

.x-register-question {
    margin-right: .4em;
}

@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;