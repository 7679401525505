@import 'styles/index.less';

.x-modelalert {
    margin: @std-padding;
}

.x-modelalert-form {
    //width: @sm-form-width * 2;
    width: 100%;
}

.x-modelalert-actions {
    margin-top: @std-padding;
}

.x-modelalert-condition {
    margin-bottom: @form-padding;
    width: 100%;
}

.x-modalalert-left {
    width: @med-form-width * 2;
}

.x-modelalert-div {
    height: 400px !important;
}

.x-modelalert-right {
    width: 600px;
    //width: 100%;
}

.x-modelalert-visual {
    height: 32px;
}

.x-modelalert-test {
    margin-top: @std-spacing;
}
@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;