@import 'styles/index.less';

.x-pager {
    margin-top: @xl-spacing;
}

.x-pager-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    min-width: 32px;
    border: solid 1px @border-color;
    color: @border-color;
    border-radius: 2px;
    cursor: pointer;
}

.x-pager-inner {
    color: @gray-8 !important;
}

.x-pager-page {
    cursor: default !important;
}

.x-pager-disabled {
    color: @disabled-color ! important;
}

.x-pager-idle {
    cursor: not-allowed !important;
}
@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;