@import 'styles/index.less';

.x-apiitem {
    width: @config-form-width;
}

.x-apiitem-name {
    font-weight: bold;
}

.x-apiitem-header {
    padding: @std-padding !important;
}

.x-apiitem-body {
    padding: @std-padding;
}

.x-apiitem-skeleton-input {
    width: 100%;
}

.x-apiitem-divider {
    margin: 3px 0 !important;
    font-weight: normal !important;
    font-size: 14px !important;
}

.x-apiitem-generate {
    text-align: right;
    margin-top: 10px;
}

.x-apiitem-query {
    //padding-left: 16px;
}

.x-apiitem-actions {
    display: flex !important;
    justify-content: flex-end !important;
    margin-top: @std-spacing !important;
    margin-bottom: @xl-spacing !important;
}

.x-apiitem-all-fields {
    display: flex;
    justify-content: flex-end;
}

.x-apiitem-all-fields-item > div {
    margin-bottom: 0;
}

.x-apiitem-error {
    color: red;
    text-align: right;
    margin-bottom: @std-spacing;
}

.x-apiitem-advanced {
    display: flex;
    justify-content: flex-end;
}

.x-apiitem-advanced button {
    margin: 0;
    padding: 0;
}
@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;