@import 'styles/index.less';

.x-conditioneditor {
}

.x-conditioneditor-logical {
    flex: 0 0 50px;
    text-align: right;
}

.x-conditioneditor-variable {
    //width: 250px !important;
    //flex: 0 0 100% !important;
    flex: 1;
    //flex: 0 0 300px;
}

.x-conditioneditor-op {
    flex: 0 0 60px;
}

.x-conditioneditor-value {
    flex: 0 0 150px;
    //width: 100% !important;
}

.x-conditioneditor-icon {
    flex: 0 0 32px;
}
@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;