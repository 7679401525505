@import 'styles/index.less';

.x-executions-status-running {
    color: @blue-6;
}

.x-executions-status-succeeded {
    color: @green-6;
}

.x-executions-status-failed {
    color: @red-6;
}

.x-executions-status-aborted {
    color: @purple-6;
}

.x-executions-status-timedout {
    color: @orange-6;
}

.x-executions-spin {
    margin-right: 6px;
    color: @blue-6;
}

.x-execution-error {
    min-width: 200px;
    width: @wide-dialog-width !important;
    padding: @std-padding;
}

.x-execution-error-body {
    height: 400px;
    overflow-y: auto;
}

@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;