@import 'styles/index.less';

.x-monitor-count {
    width: 65px;
}

.x-monitor-type {
    width: 100px;
}

.x-monitor-offset {
    width: 100px;
}

.x-monitor-label {
    margin-left: 8px;
}

.x-monitor-running {
    color: @blue-6;
}

.x-monitor-started {
    color: @geekblue-6;
}

.x-monitor-succeeded {
    color: @green-6;
}

.x-monitor-failed {
    color: @red-6;
}

.x-monitor-aborted {
    color: @purple-6;
}

.x-monitor-timedout {
    color: @orange-6;
}

.x-monitor-none {
    color: @gray-8
}

@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;