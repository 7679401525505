@import 'styles/index.less';

.x-info-last {
    display: inline-flex;
    align-items: center;
}

.x-info-icon {
    margin-left: 4px;
}

.x-info-bold {
    font-weight: @label-weight;
}

.x-info-required::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}

.x-info-colon:after {
    //content: "\00a0:";
    content: ":";
}

.x-info-nowrap {
    white-space: nowrap;
}
@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;