@import 'styles/index.less';

.x-formitem, .x-formitem-body {
    width: 100%;
}

.x-formitem-info-icon {
    margin-left: 5px;
}

.x-formitem-info-under {
    color: @gray-8;
    margin: -@sm-padding 0 @xs-padding 0;
}

.x-formitem-no-label .ant-form-item-label {
    display: none !important;
}

.x-formitem-hide-label .ant-form-item-label {
    visibility: hidden;
}

.x-formitem-fill {
    width: 100%;
}

.x-formitem-bold {
    font-weight: @label-weight;
}

.x-formitem-extra .x-formitem-label {
    width: 100%;
}

.x-formitem-extra label {
    width: 100%;
}

.x-formitem-extra label::after {
    content: none !important;
}

.x-formitem-no-error.ant-form-item {
    margin-bottom: 0 !important;
}

.x-formitem-no-error .ant-form-item-explain {
    display: none;
}

.x-formitem .ant-form-item-label {
    overflow: visible;
    white-space: break-spaces;
}

@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;