@import 'styles/index.less';

.x-stateview {
    min-width: 200px;
    width: @wide-dialog-width !important;
    padding: @std-padding;
}

.x-stateview-tabs .ant-tabs-content-holder {
    height: 450px;
    position: relative;
}

.x-stateview-data {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;