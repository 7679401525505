@import 'styles/index.less';

// .x-listeditor-list {
//     width: 100%;
// }

// .x-listeditor-right {
//     text-align: right;
//     padding-right: @lg-spacing;
//     border-right: 1px solid @border-color;
// }

// .x-listeditor-top {
//     font-weight: @semi-bold-font-weight;
//     margin-bottom: @sm-padding;
// }

// .x-listeditor-bottom {
//     color: @alt-text-color;
// }

// .x-listeditor-menu {
//     align-self: center;
// }

.x-listeditor-item {
    display: flex;
    align-items: center;
    gap: @std-padding;
    width: 100%;
}

.x-listeditor-menu {
    padding-left: @std-padding;
    border-left: 1px solid @border-color;
}

.x-listeditor-add {
    margin-top: @std-spacing;
}
@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;