@import 'styles/index.less';

.x-console {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.x-console-header {
    height: inherit !important;
    line-height: inherit !important;
    padding: 0 !important;
    background-color: @gray-3 !important;
}

.x-console-sidebar {
    height: calc(100vh - 52px);
    overflow: auto;
    transition-duration: 0.5s !important;
}

.x-console-body {
    /* height: calc(100vh - 52px); */
    //overflow: auto;
    // overflow-x: auto;
    // overflow-y: hidden;
    overflow: auto;
}

.x-console-loading {
    padding-top: @no-items-padding;
    //height: 400px;
}

@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;