@import 'styles/index.less';

@thumb-width: 270px;
@thumb-height: 157px;

.x-tutorial {
}

.x-tutorial-title {
    font-size: @lg-text-font-size;
    font-weight: @medium-font-weight;
    color: @gray-8;
}

.x-tutorial-duration {
    font-size: @med-text-font-size;
    font-weight: @regular-font-weight;
    color: @gray-8;
}

.x-tutorial-date {
    font-size: @sm-text-font-size;
    font-weight: @medium-font-weight;
    color: @gray-8;
    //margin-top: @xs-padding;
}

.x-tutorial-description {
    font-size: @med-text-font-size;
    font-weight: @regular-font-weight;
    color: @gray-7;
}

.x-tutorial-iframe {
    width: @thumb-width;
    height: @thumb-height;
    border-radius: @std-border-radius;
    border-width: 0;
}

.x-tutorial-wrapper {
    width: @thumb-width;
    height: @thumb-height;
    position: relative;
}

.x-tutorial-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: @thumb-width;
    height: @thumb-height;
    cursor: pointer;
  }
@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;