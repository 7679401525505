@import 'styles/index.less';

.x-chartwidgetviewer-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
}

.x-chartwidgetviewer-text {
    font-size: 1.2em;
}

.x-chartwidgetviewer-divider {
    margin: 10px 0;
}

// .x-chartwidgetviewer canvas {
//     width: 100% !important;
// }

// .x-chartwidgetviewer .apexcharts-canvas, .x-chartwidgetviewer svg, .x-chartwidgetviewer foreignObject {
//     width: 100% !important;
// }

@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;