@import 'styles/index.less';

.x-request-instruction {
    color: @alt-text-color;
    font-size: @lg-text-font-size;
}

.x-request-heading {
    font-size: @xl-text-font-size;
}

.x-request-footer {
    margin-top: 20px;
}

.x-request-xs {
    margin-top: 25px;
}

.x-request-sm {
    margin-top: 50px;
}

.x-request-lg {
    margin-top: 100px;
}

.x-request-image {
    display: block;
    width: 100%;
}

.x-request-content {
    padding: 0 16px;
}
@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;