@import 'styles/index.less';

.x-itemeditor-view {
    padding: @std-padding;
    //margin: @std-padding;
    //margin: 0 auto;
    //border: 1px solid @border-color;
    //border-radius: @std-border-radius;
}

.x-itemeditor-loading {
    //height: 400px;
    padding-top: @no-items-padding;
}

.x-itemeditor-header {
    padding: @std-padding !important;
}

.x-itemeditor-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    width: 100%;
}

@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;