@import 'styles/index.less';

.x-pivoteditor-close {
    text-align: center;
    margin-top: @lg-padding;
}

.x-pivoteditor-specs {
    display: flex;
    flex-direction: column;
    row-gap: @std-spacing;
}

// .x-pivoteditor-cell {
//     margin-bottom: @std-spacing;
//     display: flex;
//     justify-content: flex-end;
//     color: @primary-color;
//     font-weight: @label-weight;
// }

.x-pivoteditor-sheet label::before {
    display: none !important;
}

.x-pivoteditor-label {
    width: 40px;
    text-align: left;
}
@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;