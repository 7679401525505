@import 'styles/index.less';

.x-spacer {
    display: flex !important;
}

.x-spacer-fill {
    width: 100% !important;
}

.x-spacer-wrap {
    flex-wrap: wrap !important;
}

.x-spacer-horizontal {
    flex-direction: row !important;
}

.x-spacer-vertical {
    flex-direction: column !important;
}

.x-spacer-none {
    row-gap: 0 !important;
    column-gap: 0 !important;
}

.x-spacer-xs {
    row-gap: @xs-spacing !important;
    column-gap: @xs-spacing !important;
}

.x-spacer-sm {
    row-gap: @sm-spacing !important;
    column-gap: @sm-spacing !important;
}

.x-spacer-md {
    row-gap: @med-spacing !important;
    column-gap: @med-spacing !important;
}

.x-spacer-lg {
    row-gap: @lg-spacing !important;
    column-gap: @lg-spacing !important;
}

.x-spacer-xl {
    row-gap: @xl-spacing !important;
    column-gap: @xl-spacing !important;
}

.x-spacer-xxl {
    row-gap: @xxl-spacing !important;
    column-gap: @xxl-spacing !important;
}

.x-spacer-form {
    row-gap: @form-spacing !important;
    column-gap: @form-spacing !important;
}

.x-spacer-left {
    justify-content: flex-start !important;
}

.x-spacer-center {
    justify-content: center !important;
}

.x-spacer-right {
    justify-content: flex-end !important;
}

.x-spacer-between {
    justify-content: space-between !important;
}

.x-spacer-around {
    justify-content: space-arount !important;
}

.x-spacer-top {
    align-items: flex-start !important;
}

.x-spacer-middle {
    align-items: center !important;
}

.x-spacer-bottom {
    align-items: flex-end !important;
}
@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;