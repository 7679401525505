@import 'styles/index.less';

.x-bullets-icon svg {
    margin-top: 5px;
}

.x-bullets-blue svg {
    color: @blue-6 !important;
}

.x-bullets-green svg {
    color: @green-6 !important;
}

.x-bullets-purple svg {
    color: @purple-6 !important;
}

.x-bullets-black svg {
    color: @gray-13 !important;
}

.x-bullets-gray svg {
    color: @gray-7 !important;
}

@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;