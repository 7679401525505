@import 'styles/index.less';

.x-dashboardheading {
    display: flex;
    padding: 10px;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid @gray-4;
    background-color: @gray-1;
    // border-bottom: 1px solid #eee;
}

.x-dashboardheading-text {
    font-size: @h5-font-size !important;
    font-weight: @regular-font-weight !important;
    color: @gray-8 !important;
}

.x-dashboardheading-title {
    width: 100%;
    padding-right: @std-spacing;
}

.x-dashboardheading-buttons {
    white-space: nowrap;
}

.x-dashboardheading-saving {
    color: @gray-8;
}

.x-dashboardheading-unlocked {
    color: @red-6 !important;
}

@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;