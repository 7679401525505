@import 'styles/index.less';

.x-functiondoc-fn {
    font-size: @xxl-text-font-size;
    font-weight: @semi-bold-font-weight;
    color: @gray-8;
}

.x-functiondoc-category {
    font-size: @med-text-font-size;
    font-weight: @regular-font-weight;
    color: @gray-7;
}

.x-functiondoc-title {
    font-size: @lg-text-font-size;
    font-weight: @semi-bold-font-weight;
    color: @gray-8;
    margin: @std-padding 0 0 0;
}

.x-functiondoc-code {
    color: @green-8;
}

.x-functiondoc-params {
    margin-top: @sm-spacing;
}
@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;