@import 'styles/index.less';

.x-appleteditor {
    width: 100%;
    border-radius: @std-border-radius;
    border: 1px solid @border-color !important;
}

.x-appleteditor .ant-card-head {
    color: @gray-8;
    font-weight: @semi-bold-font-weight;
    font-size: @h8-font-size !important;
}

.x-appleteditor .ant-card-bordered {
    border: 1px solid @border-color;
}

.x-appleteditor-menu {
    padding: 0 !important;
}

.x-appleteditor-div {
    height: 400px !important;
}

.x-appleteditor-applet {
    //width: 100%;
    flex: 1;
}

.x-appleteditor-actions-select {
    width: 400px;
}

.x-appleteditor-actions {
    padding-bottom: @std-padding;
}

@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;