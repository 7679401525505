@import 'styles/index.less';

.x-iomapeditor {
    padding-bottom: @std-form-spacing;
}

.x-iomapeditor-panel {
    position: relative;
}

.x-iomapeditor-link {
    position: absolute;
    left: -23px;
    top: 5px;
}

.x-iomapeditor-empty {
    padding-top: 30px;
}

.x-iomapeditor-item {
    display: flex;
    align-items: center;
}

.x-iomapeditor-label {
    margin-top: 5px;
}

.x-iomapeditor-column {
    text-align: center;
    margin-bottom: @std-padding;
}

.x-iomapeditor-divide {
    margin-top: 5px;
    width: 12px;
}
@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;