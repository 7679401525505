@import 'styles/index.less';

.x-applicationview {
    width: 100%;
}

.x-applicationview-wrap {
    display: grid;
    width: 100%;
}

.x-applicationview-link {
    width: fit-content;
}

.x-applicationview-publisher {
    font-size: @sm-text-font-size;
    font-weight: @semi-bold-font-weight;
    color: @blue-6;
}

.x-applicationview-name {
    font-size: @lg-text-font-size;
    font-weight: @bold-font-weight;
    color: @gray-9;
}

.x-applicationview-description {
    font-size: @med-text-font-size;
    color: @gray-8;
    overflow: hidden;
}

.x-applicationview-summary {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.x-applicationview-head {
    margin-bottom: @sm-padding;
}
@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;