@import 'styles/index.less';

.x-documents {
    //width: 200px;
    //box-shadow: @shadow;
}

// .x-documents.ant-menu-vertical {
//     box-shadow: inherit !important;
// }

.x-documents-title {
    font-size: @h8-font-size;
    font-weight: @semi-bold-font-weight;
    color: @gray-7;
    //margin-bottom: @std-padding;
}
@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;