@import 'styles/index.less';

.x-valuewidgetviewer-label:extend(.x-widgetviewer-label) {
    font-weight: @medium-font-weight;
    width: 100%;
}

.x-valuewidgetviewer-description:extend(.x-widgetviewer-description) {}

.x-valuewidgetviewer-split {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.x-valuewidgetviewer-value {
    font-size: 1.5em;
    font-weight: @medium-font-weight;
    color: @gray-8;
}
@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;