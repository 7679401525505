@import 'styles/index.less';

.x-navbar {
    height: 52px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 @std-padding;
}

.x-navbar-profile {
    border-width: 0;
    color: @gray-1;
    background-color: @blue-6;
}

.x-navbar-gears {
    height: @logo-size;
}

.x-navbar-logo {
    height: @logo-size;
    margin-left: @sm-spacing;
}

.x-navbar-username {
    color: @gray-1;
}

.x-navbar-confirm-info {
    margin: 10px 0;
}

.x-navbar-confirm-icon {
    color: @error-color !important;
}

.x-navbar-confirm-error {
    color: @error-color;
    margin-top: 10px;
}

.x-navbar-group {
    width: 250px;
}

.x-navbar-label {
    font-weight: @semi-bold-font-weight;
    font-size: @sm-text-font-size;
}

.x-navbar-id {
    font-weight: @semi-bold-font-weight;
    font-size: @xs-text-font-size;
    color: @gray-7;
}
@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;