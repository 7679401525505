@import 'styles/index.less';

.x-modelitem {
    height: 100%;
}

.x-modelitem-loading {
    padding-top: @no-items-padding;
}

.x-modelitem-body {
    //padding: 10px;
    overflow-x: hidden;
}

.x-modelitem-hide {
    display: none;
}

@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;