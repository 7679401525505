@import 'styles/index.less';

.x-formdivider {
    margin-bottom: 3px;
}

.x-formdivider-divider {
    margin: 5px 0 !important;
    min-width: auto !important;
}

.x-formdivider-label {
    margin-bottom: 0px !important;
}

.x-formdivider-bold label {
    font-weight: @label-weight;
}

.x-formdivider-italic label {
    font-style: italic;
}

.x-formdivider-normal label {
    font-weight: normal;
}

.x-formdivider-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.x-formdivider-fill {
    width: 100%;
}

.x-formdivider-actions {
    display: flex;
    align-items: center;
    margin-left: @std-padding;
}

.x-formdivider-btn {
    height: auto;
    width: auto;
}

.x-formdivider-small svg {
    height: 16px;
    width: 16px;
}

.x-formdivider-medium svg {
    height: 20px;
    width: 20px;
}

.x-formdivider-large svg {
    height: 24px;
    width: 24px;
}

.x-formdivider .ant-form-item-explain {
    display: none;
}

@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;