@import 'styles/index.less';

.x-valuedisplay span {
    white-space: nowrap;
}

.x-valuedisplay-arrow span {
    font-size: 1.0em;
    padding: @sm-padding;
    font-weight: @label-weight;
    border-radius: @sm-border-radius;
}

.x-valuedisplay-positive {
    color: @green-7;
}

.x-valuedisplay-negative {
    color: @red-7;
}

.x-valuedisplay-arrow .x-valuedisplay-positive {
    background-color: @green-2;
    border: 1px solid @green-7;
}

.x-valuedisplay-arrow .x-valuedisplay-negative {
    background-color: @red-2;
    border: 1px solid @red-7;
}
@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;