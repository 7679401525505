@import '~antd/lib/style/themes/default.less';

// Lower bounds on break points in media widths.
@xxl-width: 1600px;
@xl-width: 1200px;
@lg-width: 992px;
@md-width: 768px;
@sm-width: 576px;
@xs-width: 0px;

/* 
 * ANT does not define gray color values. The values
 * below are based on the color documentation.
 *
 * See https://ant.design/docs/spec/colors
 */
@gray-1: #ffffff;
@gray-2: #fafafa;
@gray-3: #f5f5f5;
@gray-4: #f0f0f0;
@gray-5: #d9d9d9;
@gray-6: #bfbfbf;
@gray-7: #8c8c8c;
@gray-8: #595959;
@gray-9: #434343;
@gray-10: #262626;
@gray-11: #1f1f1f;
@gray-12: #141414;
@gray-13: #000000;

@dark-background-color: #001529;
@light-background-color: @gray-3;

@thin-font-weight: 100;
@extra-light-font-weight: 200;
@light-font-weight: 300;
@regular-font-weight: 400;
@medium-font-weight: 500;
@semi-bold-font-weight: 600;
@bold-font-weight: 700;
@extra-bold-font-weight: 800;
@black-font-weight: 900;

@text-weight: @regular-font-weight;
@label-weight: @semi-bold-font-weight;
@link-weight: @regular-font-weight;
//@font-bold: @semi-bold-font-weight;

//@alt-font-family: 'Bitter', serif;
//@alt-font-family: 'Merriweather', serif;
@std-font-family: 'Roboto', sans-serif;
@alt-font-family: 'Roboto Slab', serif;
@mono-font-family: 'Roboto Mono', serif;
@alt-text-color: @gray-7;
@h1-font-size: 44px;
@h2-font-size: 38px;
@h3-font-size: 32px;
@h4-font-size: 26px;
@h5-font-size: 22px;
@h6-font-size: 20px;
@h7-font-size: 18px;
@h8-font-size: 16px;
@h9-font-size: 14px;
@xxl-text-font-size: 24px;
@xl-text-font-size: 20px;
@lg-text-font-size: 16px;
@med-text-font-size: 14px;
@sm-text-font-size: 12px;
@xs-text-font-size: 10px;
@xxs-text-font-size: 9px;

@border-color: @gray-5;
@sm-border-radius: 5px;
@med-border-radius: 10px;
@lg-border-radius: 20px;
@std-border-radius: @med-border-radius;

@xs-padding: 3px;
@sm-padding: 5px;
@med-padding: 10px;
@lg-padding: 16px;
@xl-padding: 20px;
@xxl-padding: 24px;
@std-padding: @med-padding;
@form-padding: @xxl-padding;

@xxs-spacing: 1px;
@xs-spacing: 3px;
@sm-spacing: 5px;
@med-spacing: 8px;
@lg-spacing: 12px;
@xl-spacing: 16px;
@xxl-spacing: 20px;
@std-spacing: @med-spacing;
@form-spacing: 24px;

@med-form-spacing: 24px;
@std-form-spacing: @med-form-spacing;

@label-spacing: @xs-spacing;
@column-spacing: 32px;

@no-items-padding: 160px;

@max-dialog-height: 550px;
@sm-form-width: 325px;
@med-form-width: 375px;
@std-form-width: 450px;
@sm-config-form-width: 650px;
@md-config-form-width: @std-form-width * 2;
@lg-config-form-width: 1000px;
@config-form-width: @md-config-form-width;
@wide-dialog-width: @lg-config-form-width;

@shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
@logo-size: 26px;

@error-color: @red-6;
@warning-color: @orange-6;
@disabled-color: @gray-6;

@cell-error-color: @red-5;
@cell-formula-color: @blue-5;
@cell-block-color: @gray-5;

@fill: 100%;

.subLabel() {
    font-weight: @semi-bold-font-weight;
    font-size: @sm-text-font-size;
    color: @gray-7;
}