@import 'styles/index.less';

.x-label {
    display: flex;
}

.x-label-direction-row {
    flex-direction: row;
}

.x-label-direction-column {
    flex-direction: column;
}

.x-label-justification-left {
    justify-content: flex-left;
}

.x-label-justification-center {
    justify-content: center;
}

.x-label-info {
    // display: flex;
    // margin-right: @label-spacing;
}

.x-label-extra {
    margin-left: @std-spacing;
}

.x-label-extra-right {
    display: flex;
    margin-right: @label-spacing;
    justify-content: space-between;
    width: 100%
}

@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;