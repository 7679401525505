@import 'styles/index.less';

.x-inputeditor-fill {
    width: 100%;
}

.x-inputeditor-date {
    cursor: pointer;
    padding: @sm-padding;
    border-radius: @sm-border-radius;
    background-color: @gray-4;
    border: 1px solid @gray-5;
    white-space: nowrap;
}

.x-inputeditor-popup {
    width: 250px;
}

.x-inputeditor-error svg {
    color: @error-color;
}

.x-inputeditor-prefix {
    margin-right: @xs-padding;
}

.x-inputeditor-postfix {
    margin-left: @xs-padding;
}

@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;