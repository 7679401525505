@import 'styles/index.less';

.x-processors {
    padding: 0;
}

.x-processors-add {
    margin-top: @std-padding;
}

.x-processoreditor-title {
    font-size: @h7-font-size;
    font-weight: @semi-bold-font-weight;
    margin-bottom: @std-padding;
}

.x-processoreditor-actions {
    padding-top: @std-padding;
    display: flex !important;
    justify-content: flex-end;
}

.x-processoreditor-skeleton {
    width: 100% !important;
}

.x-processoreditor-side {
    display: flex;
    flex-direction: column;
    row-gap: @std-spacing;
}

.x-processoreditor-panel {
    width: 100%;
}

.x-processoreditor-info th {
    background-color: @light-background-color !important;
    width: 200px;
}

.x-processoreditor-info div {
    margin-bottom: 0;
}

.x-processoreditor-row {
    display: contents;
}
@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;