@import 'styles/index.less';

.x-modelcalculator {
    overflow: hidden;
    padding: @std-padding;
}

.x-modelcalculator-message {
    //height: 400px;
    padding-top: @no-items-padding;
}

.x-modelcalculator-active input {
    text-align: center;
    width: 100px;
}

.x-modelcalculator-header {
    // display: flex;
    // align-items: center;
    width: 100%;
    margin-bottom: @std-spacing;
}

// .x-modelcalculator-params {
//     margin-left: @std-spacing;
// }

.x-modelcalculator-sidebar {
    padding-left: 10px;
    overflow-y: auto;
}

.x-modelcalculator-sidebar-slider {
    margin-right: 0px;
}

.x-modelcalculator-sidebar-slider-enter {
    transition: margin-right 500ms ease;
    margin-right: 0px;
}

.x-modelcalculator-sidebar-slider-enter-done {
    margin-right: 0px;
}

.x-modelcalculator-sidebar-slider-exit {
    transition: margin-right 500ms ease;
    margin-right: -310px;
}

.x-modelcalculator-sidebar-slider-exit-done {
    margin-right: -310px;
}

// .x-modelcalculator-mark {
//     width: 3px;
//     height: 22px;
// }

// .x-modelcalculator-query-mark {
//     .x-modelcalculator-mark;
//     background-color: @query-color;
// }

// .x-modelcalculator-pivot-mark {
//     .x-modelcalculator-mark;
//     background-color: @pivot-color;
// }
@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;