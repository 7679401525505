@import 'styles/index.less';

.x-overviewdoc-video {
    float: none;
    clear: both;
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 0px;
}

.x-overviewdoc-video iframe {
    position: absolute;
    top: 0;
    width: 95%;
    height: 95%;
    padding: 10px;
    border-radius: @std-border-radius;
    border: 1px solid @border-color;
}

@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;