@import 'styles/index.less';

// .x-workfloweditor-skeleton {
//     width: 100% !important;
// }

.x-workfloweditor-col1 {
    padding-right: @column-spacing;
}

.x-workfloweditor-col2 {
    padding-left: @column-spacing;
}

.x-workfloweditor-iomap-output {
    font-weight: @label-weight;
    text-align: center;
    font-size: @h9-font-size !important;
    font-weight: @label-weight !important;
    margin: 5px 0 !important;
    min-width: auto !important;    
}

@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;