@import 'styles/index.less';

.x-textmessage-warning {
    color: @warning-color;
}

.x-textmessage-error {
    color: @error-color;
}

.x-textmessage-fill {
    width: 100%;
}

.x-textmessage-bold {
    font-weight: @label-weight;
}

.x-textmessage-italic {
    font-style: italic;
}

.x-textmessage-xxs {
    font-size: @xxs-text-font-size;
}

.x-textmessage-xs {
    font-size: @xs-text-font-size;
}

.x-textmessage-sm {
    font-size: @sm-text-font-size;
}

.x-textmessage-med {
    font-size: @med-text-font-size;
}

.x-textmessage-lg {
    font-size: @lg-text-font-size;
}

.x-textmessage-xl {
    font-size: @xl-text-font-size;
}

.x-textmessage-xxl {
    font-size: @xxl-text-font-size;
}

@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;