@import 'styles/index.less';

.x-business {
    color: @alt-text-color;
    font-size: @xl-text-font-size;
    padding: 0 @std-padding;
}

.x-business-overview {
    position: relative;
    font-family: @alt-font-family;
    background-image: linear-gradient(to right, @blue-1, white);
    border-radius: @std-border-radius;
    width: 100%;
    //height: 600px;
    color: @blue-6;
}

.x-business-description {
    margin-top: 20px;
}

.x-business-overview-title {
    font-weight: @regular-font-weight;
    font-size: @xxl-text-font-size;
}

.x-business-overview-xs .x-business-overview-title {
    font-size: @h2-font-size;
    margin-top: 30px;
}

.x-business-overview-md .x-business-overview-title {
    font-size: @h1-font-size;
    margin-top: 60px;
}

.x-business-overview-subtitle {
    font-weight: @light-font-weight;
    margin-top: 1em;
}

.x-business-overview-xs .x-business-overview-subtitle {
    font-size: @h4-font-size;
}

.x-business-overview-md .x-business-overview-subtitle {
    font-size: @h3-font-size;
}

.x-business-overview-graphic {
    max-width: 100%;
    max-height: 100%;
    margin: 30px 0;
}

.x-business-usecase-image {
    background-color: #f7fcfe;
}

.x-business-section {
    margin-top: 75px;
}

.x-business-title {
    font-weight: @medium-font-weight;
    font-size: @xl-text-font-size;
    color: @alt-text-color;
    margin-bottom: 10px;
}

.x-business-blurb {
    margin: 10px 0;
    font-size: @lg-text-font-size !important;
}

.x-business-blurb > div {
    margin-top: 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    -webkit-transform: translateY(0) !important;
    transform: translateY(0) !important;
    border-bottom: 1px solid @gray-4;
}

.x-business-blurbs {
    margin: 30px 0;
}

.x-business-feature-image {
    width: 100%;
    padding: 10px;
    border-radius: @std-border-radius;
    border: 1px solid @border-color;
}

.x-business-feature-title {
    font-size: @med-text-font-size;
    font-weight: @medium-font-weight;
    margin-left: 24px;
}

.x-business-feature-list {
    background-color: @gray-2;
    border: 1px solid @border-color;
    border-radius: @std-border-radius;
}

.x-business-feature-section {
    margin-bottom: 60px;
}

.x-business-feature-item {
    font-size: @lg-text-font-size;
    font-weight: @regular-font-weight;
    color: @alt-text-color !important;
}

.x-business-video {
    float: none;
    clear: both;
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.x-business-video iframe {
    position: absolute;
    top: 0;
    //left: 0;
    width: 75%;
    height: 75%;
    padding: 10px;
    border-radius: @std-border-radius;
    border: 1px solid @border-color;
}

.x-business-checkbox {
    font-size: @xl-text-font-size;
    margin-top: @xl-spacing;
    align-items: flex-start !important;
}

.x-business-checkbox svg {
    color: @green-6 !important;
    font-size: @xl-text-font-size;
    margin-top: 5px;
}

.x-business-try {
    width: 100%;
    text-align: center;
    margin-bottom: @xxl-padding;
}

.x-business-register {
    width: 100%;
    text-align: center;
    margin-bottom: @std-padding;
}
@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;