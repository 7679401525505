@import 'styles/index.less';

.ag-theme-alpine {
    //--ag-internal-calculated-line-height: var(--ag-line-height, calc(var(--ag-row-height) - 1px));
    // --ag-internal-padded-row-height: calc(var(--ag-row-height) - 1px) !important;
    --ag-cell-horizontal-padding: calc(var(--ag-grid-size) * 2 + 1px) !important;
    //--ag-cell-horizontal-padding: 13px;
    --ag-border-color: @border-color !important;
    --ag-header-background-color: @gray-3 !important;
    --ag-range-selector-border-color: @blue-6 !important;
}

.x-sheetitem .ag-header-cell-label {
    justify-content: center;
    font-weight: @label-weight;
}


// .x-sheetitem .ag-horizontal-left-spacer {
//     overflow-x: hidden;
//     background-color: var(--ag-header-background-color);
// }

.x-sheetitem .ag-cell {
    //padding-top: 1px;
}

// .ag-cell:focus {
//     //padding-left: calc(padding-left + 1px);
//     --ag-cell-horizontal-padding: calc(var(--ag-grid-size) * 2);
//     //margin-top: -5px;
// }

// .ag-cell:focus {
//     --ag-internal-calculated-line-height: var(--ag-line-height, calc(var(--ag-row-height) - 3px));
//     --ag-cell-horizontal-padding: calc(var(--ag-grid-size) * 2);
// }

.x-sheetitem .ag-ltr .ag-cell {
//    border: none;
    // padding-top: 1px;
    // padding-left: 1px;
    border-right: 1px solid @border-color;
}

.x-sheetitem {
    width: 100%;
    height: 100%;
}

.x-sheetitem-cell {
    //text-align: right;
    //border-right: 1px solid @border-color !important;
    // border-left: 1px solid @border-color !important;
    //margin-left: 1px;
}

.x-sheetitem-row {
    background-color: @gray-3;
    border: none !important;
    border-right: 1px solid @border-color !important;
    padding: 0;
}

@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;