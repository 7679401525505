@import 'styles/index.less';

.x-personal {
    color: @alt-text-color;
    font-size: @xl-text-font-size;
    padding: 0 @std-padding;
}

.x-personal-overview {
    position: relative;
    font-family: @alt-font-family;
    background-image: linear-gradient(to right, @blue-1, white);
    border-radius: @std-border-radius;
    width: 100%;
    color: @blue-6;
}

.x-personal-try {
    width: 100%;
    text-align: center;
    margin-bottom: @xxl-padding;
}

.x-personal-business {
    width: 100%;
    text-align: center;
    margin-top: @xxl-padding;
}

.x-personal-description {
    margin-top: 20px;
}

.x-personal-overview-title {
    font-weight: @regular-font-weight;
    font-size: @xxl-text-font-size;
}

.x-personal-overview-xs .x-personal-overview-title {
    font-size: @h2-font-size;
    margin-top: 30px;
}

.x-personal-overview-md .x-personal-overview-title {
    font-size: @h1-font-size;
    margin-top: 60px;
}

.x-personal-overview-subtitle {
    font-weight: @light-font-weight;
    margin-top: 1em;
}

.x-personal-overview-xs .x-personal-overview-subtitle {
    font-size: @h4-font-size;
}

.x-personal-overview-md .x-personal-overview-subtitle {
    font-size: @h3-font-size;
}

.x-personal-overview-graphic {
    max-width: 100%;
    max-height: 100%;
    margin: 30px 0;
    border-radius: @std-border-radius;
}

.x-personal-usecase-image {
    background-color: #f7fcfe;
}

.x-personal-section {
    margin-top: 75px;
}

.x-personal-title {
    font-weight: @light-font-weight;
    font-size: @xxl-text-font-size;
    color: @alt-text-color;
    margin-bottom: 10px;
}

.x-personal-blurb {
    margin: 10px 0;
    font-size: @lg-text-font-size !important;
}

.x-personal-blurb > div {
    margin-top: 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    -webkit-transform: translateY(0) !important;
    transform: translateY(0) !important;
    border-bottom: 1px solid @gray-4;
}

.x-personal-blurbs {
    margin: 30px 0;
}

.x-personal-checkbox {
    font-size: @xl-text-font-size;
    margin-top: @xl-spacing;
    align-items: flex-start !important;
}

.x-personal-checkbox svg {
    color: @green-6 !important;
    font-size: @xl-text-font-size;
    margin-top: 5px;
}

.x-personal-footer {
    margin-top: @lg-padding;
    font-size: @med-text-font-size;
    width: 100%;
    text-align: center;
}

.x-personal-center {
    width: 100%;
    text-align: center;
}

.x-personal-frame {
    padding: 10px;
    border-radius: @std-border-radius;
    border: 1px solid @border-color;
}



@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;