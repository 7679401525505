@import 'styles/index.less';

.x-queries-items {
    display: flex;
    flex-direction: column;
    row-gap: @std-spacing;
}

.x-queries-add {
    display: flex;
    justify-content: center;
    padding-top: @lg-padding;
}

.x-queries-hdr {
    display: flex;
    justify-content: space-between;
    margin-bottom: @sm-spacing;
    color: @gray-8;
}

.x-queries-hdr button {
    //font-weight: @text-weight;
    font-weight: @label-weight;
}

.x-queries-sch button {
    padding: 0;
    margin-right: @label-spacing;
}

.x-queries-sch-on {
    color: @green-6;
}

.x-queries-sch-off {
    color: @red-6;
}

.x-queries-runall {
    margin: 0 !important;
    padding: 0 !important;
}
@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;