@import 'styles/index.less';

// .x-queryeditor-body {
//     min-height: 120px;
// }

// .x-queryeditor-builder {
//     margin-top: @std-form-spacing !important;
// }

.x-querybuilder-advanced {
    margin: 0 !important;
    padding: 0 !important;
    font-weight: @medium-font-weight !important;
}

.x-querybuilder-all-fields-item {
    margin-bottom: @std-spacing !important;
    width: auto;
}

.x-querybuilder-menu {
    width: 500px;
}
@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;