@import 'styles/index.less';

.x-itemtable {
}

.x-itemtable-table {
    padding: @std-padding;
}

.x-itemtable-skeleton {
    display: flex;
    gap: @std-spacing;
}

.x-itemtable-skeleton-name {
    flex-grow: 5;
    width: 100% !important;
}

.x-itemtable-skeleton-type {
    flex-grow: 1;
    width: 100% !important;
}

.x-itemtable-skeleton-date {
    flex-grow: 2;
    width: 100% !important;
}

.x-itemtable-header {
    padding: @std-padding @std-padding 0 @std-padding !important;
}

.x-itemtable-header-title {
    width: 100%;
    font-size: @xxl-text-font-size;
}

.x-itemtable-body {
    padding: @std-padding;
}

.x-itemtable-count {
    .subLabel;
    // color: @gray-7;
    // font-weight: @medium-font-weight;
}
@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;