@import 'styles/index.less';

.x-alertinputs {
    width: 100%;
}

.x-alertinputs-header {
    margin-bottom: @std-padding;
}

.x-alertinputs-name {
    font-size: @xxl-text-font-size;
    font-weight: @medium-font-weight;
    color: @gray-8;
}

.x-alertinputs-desc {
    font-size: @med-text-font-size;
    font-weight: @regular-font-weight;
    color: @gray-7;
}

.x-alertinputs-add {
    margin-top: @std-padding;
}

.x-alertinputs-instruct {
    font-size: @lg-text-font-size;
    font-weight: @regular-font-weight;
    color: @gray-8;
}

.x-alertinputs-fill {
    width: 100%;
}

.x-alertinputs-op {
    width: 190px;
}

.x-alertinputs-value {
    width: 100px;
}

.x-alertinputs-merge {
    margin-right: -@sm-padding;
}

.x-alertinputs-warnings {
    margin-top: @std-spacing;
}

.x-alertinputs-input {
    display: inline-block;
    padding-bottom: @std-padding;
}

.x-alertinputs-forminput {
    margin: -3px @sm-spacing 3px @sm-spacing !important;
}
@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;