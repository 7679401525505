@import 'styles/index.less';

.x-editprofile-info {
    margin-bottom: @std-padding;
    font-weight: @medium-font-weight;
}

.x-editprofile-footer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: @lg-padding;
}

.x-editprofile-spouse {
    margin-bottom: @std-padding !important;
}

.x-editprofile-persona {
    margin-bottom: @lg-padding;
}
@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;