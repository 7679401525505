@import 'styles/index.less';

.x-groups-setup {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
}

.x-groups-intro {
    font-size: @lg-text-font-size;
    color: @alt-text-color;
    margin: 50px 0 30px 0;
}

.x-groups-link-enable {
    pointer-events: inherit;
}

.x-groups-link-disable {
    pointer-events: none;
}

.x-groups-active svg {
    color: @green-8;
}

@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;