@import 'styles/index.less';

// .x-applicationinstaller {
//     padding: 0px;
// }

// .x-applicationinstaller-body {
//     padding: @xxl-padding;
// }

.x-applicationinstaller-view {
    width: 100%;
}

.x-applicationinstaller-packs {
    width: 100%;
    overflow-y: auto;
    max-height: @max-dialog-height;
}

.x-applicationinstaller-pack {
    width: 100%;
    border: 1px solid @border-color;
    border-radius: @std-border-radius;
    padding: @std-padding;
    background-color: @gray-2;
}

.x-applicationinstaller-category {
    width: 50%;
}

.x-applicationinstaller-access {
    width: 50%;
}

.x-applicationinstaller-empty {
    width: 100%;
    text-align: center;
}

.x-applicationinstaller-item {
    width: 100%;
    border: 1px solid @border-color;
    border-radius: @std-border-radius;
    padding: @std-padding;
    background-color: @gray-3;
}

.x-applicationinstaller-select {
    padding-bottom: @std-padding !important;
}

.x-applicationinstaller-fill {
    width: 100%;
}

.x-applicationinstaller-install {
    font-weight: @medium-font-weight;
    color: @blue-6;
    height: 32px;
}

// .x-applicationinstaller-info {
//     margin-top: @std-spacing;
// }

// .x-applicationinstaller-details {
//     margin-top: @std-padding;
// }

@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;