@import 'styles/index.less';

.x-frame {
    width: 100%;
}

.x-frame-top {
    padding: 0 @sm-padding;
    position: relative;
}

.x-frame-title {
    font-weight: @extra-light-font-weight;
    font-size: @h3-font-size;
    display: inline;
}

.x-frame-extra {
    position: absolute;
    top: 50%;
    padding: 0;
    right: 10px;
    transform: translateY(-50%);
}

.x-frame-body {
    width: 100%;
    padding: @xxl-padding;
    font-size: @lg-text-font-size;
    color: @gray-8;
}

.x-frame-body-border {
    border: 1px solid @border-color;
    border-radius: @std-border-radius;
}

.x-frame-desc {
    margin-bottom: @xl-spacing;
    font-size: @med-text-font-size;
    color: @gray-7;
}
@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;