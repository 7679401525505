@import 'styles/index.less';

.x-formulaeditor {
    display: flex;
    align-items: center;
    width: 100%;
    column-gap: @std-spacing;
}

.x-formulaeditor-img svg {
    height: 20px;
    width: 20px;
    margin-right: -3px;
}

.x-formulaeditor-input {
    font-family: @mono-font-family;
}

.x-formulaeditor textarea {
    padding: 4px;
}
@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;