@import 'styles/index.less';

.x-formframe-top {
    padding: 0 10px;
    position: relative;
}

.x-formframe-col {
    width: 100%;
}

.x-formframe-title {
    font-weight: @extra-light-font-weight;
    font-size: @h3-font-size;
    display: inline;
}

.x-formframe-extra {
    position: absolute;
    top: 50%;
    padding: 0;
    right: 10px;
    transform: translateY(-50%);
}

.x-formframe-error {
    margin-top: 5px;
    color: @error-color;
}

.x-formframe-footer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-top: @std-padding;
}

.x-formframe-body {
    width: 100%;
    border: 1px solid @border-color;
    border-radius: @std-border-radius;
    padding: @xxl-padding;
}

.x-formframe-desc {
    font-size: @med-text-font-size;
    margin-bottom: @xl-spacing;
}
@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;