@import 'styles/index.less';

.x-parametercell {
    position: relative;
    height: 32px;
    min-width: calc(100% - 40px);
}

.x-parametercell:focus {
    outline: 1px solid @blue-6 !important;
}

.x-parametercell-edit {
    border: 0px solid;
    box-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4);
}

.x-parametercell-noedit {
    border: 1px solid @border-color;
    display: flex;
    align-items: center;
    height: 100%;
}

.x-parametercell-value {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 11px;
    height: 32px;
    line-height: 32px;
}

.x-parametercell-options {
    width: 100%;
}

.x-parametercell-noedit:focus {
    outline: 1px solid @blue-6 !important;
}

.x-parametercell-mark .x-errorcell-mark, .x-parametercell-mark .x-formulacell-mark {
    top: 0px;
    right: -5px;
}

@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;