@import 'styles/index.less';

.x-versionpackager-info {
    margin-bottom: @lg-spacing;
}

.x-versionpackager-name {
    font-size: @xl-text-font-size;
    font-weight: @semi-bold-font-weight;
    color: @gray-8;
}

.x-versionpackager-summary {
    font-size: @med-text-font-size;
    font-weight: @medium-font-weight;
    color: @gray-9;
}

.x-versionpackager-description {
    font-size: @med-text-font-size;
    font-weight: @regular-font-weight;
    color: @gray-7;
}

.x-versionpackager-version {
    color: @blue-6;
}

.x-versionpackager-alert {
    font-weight: @medium-font-weight;
}

.x-versionpackager-add {
    margin-top: @std-spacing;
}

.x-versionpackager-error {
    color: @error-color;
    margin-top: @std-padding;
}

.x-versionpackager-disabled {
    color: @disabled-color !important;
}

.x-versionpackager-type {
    color: @gray-8;
    font-weight: @semi-bold-font-weight;
}

.x-versionpackager-access {
    color: @gray-8;
}

.x-versionpackager-create {
    font-weight: @regular-font-weight;
}

@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;