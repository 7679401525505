@import 'styles/index.less';

.x-pricing {
    font-family: @std-font-family;
}

 .x-pricing-shadow {
     box-shadow: @shadow;
 }

.x-pricing-header {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 25px;
}

.x-pricing-body {
    font-size: @lg-text-font-size;
    display: flex;
    flex-direction: column;
    //text-align: center;
    color: @gray-8;
    padding: 25px;
}

.x-pricing-title {
    font-weight: bold;
    font-size: @h5-font-size;
}

.x-pricing-description {
    margin-bottom: 20px;
    font-size: @lg-text-font-size;
}

.x-pricing-price {
    font-size: @lg-text-font-size;
}

.x-pricing-check {
    margin-top: @sm-padding;
}

.x-pricing-check svg {
    color: @green-6;
}

.x-pricing-triangle {
    position: absolute;
    bottom: -25px;
    width: 0;
    height: 0;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;
    border-top: 30px solid;
}

@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;