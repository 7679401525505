@import 'styles/index.less';

.x-modelapplet {
    height: 100%;
    margin: @std-padding;
}

.x-modelapplet-loading {
    padding-top: @no-items-padding;
}

.x-modelapplet-empty {
    padding-top: @no-items-padding;
}

.x-modelapplet-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

// .x-modelapplet-actions {
//     padding-bottom: @std-padding;
// }

// .x-modelapplet-actions-select {
//     width: 350px;
// }

.x-modelapplet-widget {
    display: inline-block;
    padding: @std-padding;
}

.x-modelapplet-widget-title {
    width: 100%;
}

.x-modelapplet-form {
    width: @sm-form-width;
}


@font-family: Roboto, Helvetica, Arial, sans-serif;@btn-default-color: @primary-color;@btn-font-weight: 400;@layout-body-background: @white;@layout-header-background: @white;@layout-header-padding: 0px @std-padding;@layout-footer-padding: 0px 0std-padding;@layout-sider-background: @white;@modal-header-bg: @gray-3;@menu-item-height: 32px;@menu-inline-toplevel-item-height: 32px;@border-width-base: 1px;@card-radius: 8px;@card-head-background: @gray-3;@collapse-header-bg: @gray-3;